import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandRock,
  faHandPaper,
  faHandScissors,
  faList,
  faTimes,
  faQuestion,
  faXmark,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";

// Import sound files
const clickSound = new Audio('/sounds/rps-clicksound.mp3');
const toggleSound = new Audio('/sounds/rps-clicksound.mp3');

const choices = ['rock', 'paper', 'scissors'];

const getRandomChoice = () => choices[Math.floor(Math.random() * choices.length)];

const determineWinner = (userChoice, computerChoice) => {
  if (userChoice === computerChoice) return 'draw';
  if (
    (userChoice === 'rock' && computerChoice === 'scissors') ||
    (userChoice === 'paper' && computerChoice === 'rock') ||
    (userChoice === 'scissors' && computerChoice === 'paper')
  ) {
    return 'user';
  }
  return 'computer';
};

const App = () => {
  const [userChoice, setUserChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState('');
  const [history, setHistory] = useState([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [theme, setTheme] = useState('dark'); // Default to dark mode

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  const handleUserChoice = (choice) => {
    clickSound.play(); // Play click sound on button click
    const computerChoice = getRandomChoice();
    const winner = determineWinner(choice, computerChoice);

    const round = history.length + 1;
    setUserChoice(choice);
    setComputerChoice(computerChoice);
    setResult(winner);

    const gameResult = {
      round,
      userChoice: choice,
      computerChoice,
      result: winner,
    };

    setHistory([gameResult, ...history]);
  };

  const toggleHistory = () => {
    toggleSound.play(); // Play toggle sound for opening/closing history
    setIsHistoryOpen(!isHistoryOpen);
  };

  const closeHistory = () => {
    toggleSound.play(); // Play toggle sound for closing history
    setIsHistoryOpen(false);
  };

  const toggleInfo = () => {
    toggleSound.play(); // Play toggle sound for opening/closing info modal
    setIsInfoOpen(!isInfoOpen);
  };

  const latestRound = history[0] || { round: 0 }; // Default to 0 if history is empty

  return (
    <div className={`App ${theme}`}>
      <img src="./images/rps-icon.png" className="logo" alt="logo" />
      <button className="theme-toggle" onClick={toggleTheme}>
        <FontAwesomeIcon icon={theme === "dark" ? faSun : faMoon} size="2x" />
      </button>
      <h1>Rock Paper Scissors</h1>
      {latestRound.round > 0 && (
        <div className="round-number">Round: {latestRound.round}</div>
      )}
      <div className="choices">
        {choices.map((choice) => (
          <button
            key={choice}
            onClick={() => handleUserChoice(choice)}
            className="choice-button"
          >
            <FontAwesomeIcon
              icon={
                choice === "rock"
                  ? faHandRock
                  : choice === "paper"
                  ? faHandPaper
                  : faHandScissors
              }
              size="2x"
              className="fa-icon"
            />
            <p>{choice.charAt(0).toUpperCase() + choice.slice(1)}</p>
          </button>
        ))}
      </div>
      {userChoice && computerChoice && (
        <div className="result">
          <h2>
            You chose: <span className="outcome">{userChoice}</span>
          </h2>
          <h2>
            Computer chose: <span className="outcome">{computerChoice}</span>
          </h2>
          <h2>
            Result:{" "}
            <span
              className={`outcome ${
                result === "draw" ? "draw" : result === "user" ? "win" : "lose"
              }`}
            >
              {result === "draw"
                ? "It's a draw!"
                : result === "user"
                ? "You win!"
                : "You lose!"}
            </span>
          </h2>
        </div>
      )}
      <button className="history-toggle" onClick={toggleHistory}>
        <FontAwesomeIcon icon={faList} size="2x" />
      </button>
      <div className={`history-menu ${isHistoryOpen ? "open" : ""}`}>
        <button className="close-history" onClick={closeHistory}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        <h2>History</h2>
        <div className="history-content">
          {history.map((item) => (
            <div key={item.round} className="history-item">
              <p>
                <strong>Round {item.round}</strong>
              </p>
              <div className="history-status-line">
                <p>
                  <strong>You chose:</strong>{" "}
                  <span className="outcome">{item.userChoice}</span>
                </p>
                <p>
                  <strong>Computer chose:</strong>{" "}
                  <span className="outcome">{item.computerChoice}</span>
                </p>
              </div>
              <p>
                <strong>Result:</strong>{" "}
                <span
                  className={`outcome ${
                    item.result === "draw"
                      ? "draw"
                      : item.result === "user"
                      ? "win"
                      : "lose"
                  }`}
                >
                  {item.result === "draw"
                    ? "Draw"
                    : item.result === "user"
                    ? "Win"
                    : "Lose"}
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
      <button className="info-toggle" onClick={toggleInfo}>
        <FontAwesomeIcon icon={isInfoOpen ? faXmark : faQuestion} size="2x" />
      </button>
      {isInfoOpen && (
        <div className="info-modal">
          <h2>About the Game</h2>
          <p>
            Rock Paper Scissors is a simple hand game played between two people.
            Each player simultaneously forms one of three shapes with an
            outstretched hand:
          </p>
          <ul>
            <li>Rock (a fist)</li>
            <li>Paper (an open hand)</li>
            <li>Scissors (a V-shaped hand gesture)</li>
          </ul>
          <p>The game has three possible outcomes other than a tie:</p>
          <ul>
            <li>Rock crushes Scissors</li>
            <li>Scissors cuts Paper</li>
            <li>Paper covers Rock</li>
          </ul>
          <p>Enjoy the game!</p>
        </div>
      )}
    </div>
  );
};

export default App;
